<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Toon</label>
            <v-select
              v-model="itemsPerPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>contacten</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                @input="refetchData"
                class="d-inline-block mr-1"
                placeholder="Zoeken..."
              />
              <!-- <b-button
                variant="primary"
                @click="isAddNewcontactsidebarActive = true"
              >
                <span class="text-nowrap">Toevoegen</span>
              </b-button> -->
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refContactListTable"
        class="position-relative"
        :items="fetchcontacts"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Geen contacten gevonden"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(displayName)="data">
          <b-media vertical-align="center">
            <b-link
              :to="{ name: 'contacts-edit', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{
                data.item.displayName ||
                `${data.item.firstname} ${data.item.lastname}`
              }}
            </b-link>
            <small class="text-muted">{{ data.item.email }}</small>
          </b-media>
        </template>

        <!-- Column: Type -->
        <template #cell(type)="data">
          <b-badge
            pill
            :variant="`light-${resolveContactRoleVariant(data.item.type)}`"
            class="text-capitalize"
          >
            {{ getRoleSingular(data.item.type) }}
          </b-badge>
        </template>
        <!-- Column: Children -->
        <template #cell(children)="data">
          <b-avatar-group v-if="Object.keys(children).length > 0" size="32px">
            <b-avatar
              :key="c"
              v-for="c in data.item.children"
              :to="{ name: 'users-edit', params: { uoid: c } }"
              v-b-tooltip.hover
              class="pull-up"
              :title="children[c].displayName"
              :text="avatarText(children[c].displayName)"
              :src="
                children[c].profilePic ? children[c].profilePic.small : null
              "
            />
          </b-avatar-group>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            v-b-tooltip.hover.top="
              data.item.uid
                ? 'Er is een Tapp-gebruiker gekoppeld ✅'
                : 'Er is nog geen Tapp-gebruiker gekoppeld 😢'
            "
            :variant="`light-${resolveUserStatusVariant(
              data.item.uid ? 'active' : 'inactive'
            )}`"
            class="text-capitalize"
          >
            {{ data.item.uid ? "actief" : "inactief" }}
          </b-badge>
        </template>

        <template #cell(actions)="data">
          <b-dropdown
                variant="link"
                size="sm"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreHorizontalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>

                <b-dropdown-item :to="{ name: 'contacts-edit', params: { id: data.item.id } }">
                  <feather-icon icon="Edit2Icon" />
                  <span class="align-middle ml-50">Bewerken</span>
                </b-dropdown-item>
                 <b-dropdown-item v-if="data.item.email && !data.item.uid" @click="sendInvite(data.item.id)">
      <feather-icon class="mr-1" icon="SendIcon" />
      {{ data.item.firstname || data.item.displayName }} uitnodigen om Tapp te
      gebruiken</b-dropdown-item
    >

                <b-dropdown-item variant="danger" @click="removeContact(data.item)">
                  <feather-icon icon="Trash2Icon" />
                  <span class="align-middle ml-50">Verwijderen</span>
                </b-dropdown-item>
              </b-dropdown>
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >{{ dataMeta.from }} tot {{ dataMeta.to }} van
              {{ dataMeta.of }} contacten</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalcontacts"
              :per-page="itemsPerPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BAvatarGroup,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  VBModal,
  VBTooltip,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import mainMixin from "@/mixins/mainMixin";
import userVerification from "@/mixins/userVerification";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  mixins: [mainMixin, userVerification],
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BAvatarGroup,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    FeatherIcon,
  },
  directives: {
    "b-modal": VBModal,
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      searchQuery: "",
      selected: [],
      itemsPerPage: 25,
      currentPage: 1,
      isMounted: false,
      perPageOptions: [10, 25, 50, 100],
      tableColumns: [
        { key: "displayName", label: "Naam", sortable: true },
        { key: "children", label: "Kinderen", sortable: false },
        { key: "type", sortable: false },
        { key: "status", sortable: false },
        { key: "actions", label: "Acties", sortable: false },
      ],
      sortBy: "displayName",
      isSortDirDesc: false,
      refContactListTable: this.$refs["refContactListTable"],
      children: {},
    };
  },
  computed: {
    orgId() {
      if (this.$store.state.organisation) {
        return this.$store.state.organisation.id;
      } else {
        return null;
      }
    },
    contactsData() {
      return this.$store.state.userManagement.contacts;
    },
    
    totalcontacts() {
      return this.$store.state.userManagement.contacts
        ? this.$store.state.userManagement.contacts.length
        : 0;
    },
    dataMeta() {
      const localItemsCount =
        this.refContactListTable && this.refContactListTable.value
          ? this.refContactListTable.value.length
          : 0;

      var to = this.itemsPerPage * this.currentPage + localItemsCount;
      if (this.totalcontacts != 0 && to > this.totalcontacts) {
        to = this.totalcontacts;
      }

      return {
        from:
          this.itemsPerPage * (this.currentPage - 1) +
          (localItemsCount ? 1 : 0) +
          1,
        to: to,
        of: this.totalcontacts,
      };
    },
  },
  methods: {
    avatarText: avatarText,
     sendInvite(contactId) {
      this.loading = true;
      this.inviteContact(
        contactId
      ).then(() => (this.loading = false));
    },
    refetchData() {
      this.refContactListTable.refresh();
    },
    fetchcontacts(ctx, callback) {
      if (
        this.$store.state.userManagement.contacts &&
        this.$store.state.userManagement.contacts.length > 1
      ) {
        this.matchChildren();
        callback(
          this.filtercontacts(this.$store.state.userManagement.contacts)
        );
      } else {
        this.$store
          .dispatch(
            "userManagement/fetchAllContacts",
            this.$store.state.organisation.id
          )
          .then(() => {
            this.matchChildren();
            callback(
              this.filtercontacts(this.$store.state.userManagement.contacts)
            );
          })
          .catch((err) => {
            console.error(err);
          });
      }
    },
    matchChildren() {
      this.$store.state.userManagement.contacts.forEach((c) => {
        if (c.children) {
          c.children.forEach((ch) => {
            var i = this.$store.state.userManagement.users.findIndex(
              (e) => e.uoid == ch
            );
            if (i > 0) {
              this.children[ch] = this.$store.state.userManagement.users[i];
            } else {
              console.log(ch);
            }
          });
        }
      });
    },
    fetchUsers() {
      if (
        !(
          this.$store.state.userManagement.users &&
          this.$store.state.userManagement.users.length > 1
        )
      ) {
        this.$store.dispatch(
          "userManagement/fetchUsers",
          this.$store.state.organisation.id
        );
      }
    },
    filtercontacts(contacts) {
      var sortOrder = 1;
      if (this.isSortDirDesc) sortOrder = -1;
      contacts.sort(
        (a, b) =>
          (a[this.sortBy] > b[this.sortBy]
            ? 1
            : b[this.sortBy] > a[this.sortBy]
            ? -1
            : 0) * sortOrder
      );
      contacts = this.searchArray(contacts, this.searchQuery)
      return contacts.slice(this.dataMeta.from - 1, this.dataMeta.to);
    },
    
    compare(a, b, field) {
      if (a[field] < b[field]) {
        return -1;
      }
      if (a[field] > b[field]) {
        return 1;
      }
      return 0;
    },
    removeContact(c){
      this.$bvModal
        .msgBoxConfirm(`Je staat op het punt ${c.displayName} te verwijderen. Dit kan niet ongedaan gemaakt worden.`, {
          title: 'Ben je zeker?',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Verwijderen',
          cancelTitle: 'Annuleren',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if(value){
            this.$store
            .dispatch("userManagement/removeContact", {
              orgId: this.$store.state.organisation.id,
              contact: c
            })
            .then(() => {
              this.refetchData();
              this.$toast({
              component: ToastificationContent,
              props: {
                title: "Contact verwijderd!",
                icon: "CheckIcon",
                variant: "danger",
              },
            });
            });
          }
        })
    },
  },
  mounted() {
    this.refContactListTable = this.$refs["refContactListTable"];
    this.fetchUsers();
    this.$watch(
      (vm) => [vm.currentPage, vm.itemsPerPage, vm.sortBy, vm.isSortDirDesc],
      (val) => {
        this.refetchData();
      },
      {
        immediate: true, // run immediately
        deep: true, // detects changes inside objects. not needed here, but maybe in other cases
      }
    );
  },
  beforeDestroy() {
    // this.unwatch();
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
